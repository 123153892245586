import Vue from 'vue'
import store from '../store'
import BaseRepository from './BaseRepository.js'
import Log from '@/utils/logger'
import moment from 'moment'
import RxUtils from '@/utils/rx_utils'
import * as _ from 'lodash'

export default class LocalDataRepository extends BaseRepository {

  onMonthFromNow() {
    return moment().add(1, 'month').calendar()
  }

  async addProductToLocalCart(productId, variant) {
    let localCart = this.loadLocalCart()
    let previousProduct = localCart.find((p) => p.product_id === productId)
    if (previousProduct != null) {
      localCart.map((p) => {
        if (p.product_id === productId) {
          p.count++
          return p
        }
        return p
      })
    } else {
      localCart.push({
        'product_id': productId,
        'variant': variant,
        'count': 1,
      })
    }
    Log.d("localCart", RxUtils.rxToObject(localCart))
    Vue.$cookies.set('local_cart', JSON.stringify(localCart), this.onMonthFromNow())
    store.commit('updateCart', localCart)
  }

  async removeProductFromLocalCart(productId) {
    let localCart = this.loadLocalCart()
    if (localCart != null) {
      let previousProduct = localCart.find((p) => p.product_id === productId)
      if (previousProduct != null) {
        if (previousProduct.count > 1) {
          localCart.map((p) => {
            if (p.product_id === productId) {
              p.count--
            }
            return p
          })
        } else {
          _.remove(localCart, (it) => {
            return it.product_id === productId
          })
        }
      }
    }
    Log.d("localCart", RxUtils.rxToObject(localCart))
    Vue.$cookies.set('local_cart', JSON.stringify(localCart), this.onMonthFromNow())
    store.commit('updateCart', localCart)
  }

  emptyLocalCart() {
    let localCart = []
    Vue.$cookies.set('local_cart', JSON.stringify(localCart), this.onMonthFromNow())
    store.commit('updateCart', localCart)
  }

  loadLocalCart() {
    let localCart = []
    try {
      localCart = Vue.$cookies.get('local_cart')
    } catch (exception) {
      Log.w('no local cart', exception)
    }
    if (localCart == null) return []
    return localCart
  }

  loadLocalShippingAddress() {
    return Vue.$cookies.get('local_shipping_address')
  }

  saveLocalShippingAddress(address) {
    Vue.$cookies.set('local_shipping_address', JSON.stringify(address),
      this.onMonthFromNow())
  }

  loadLocalBillingAddress() {
    return Vue.$cookies.get('local_billing_address')
  }

  saveLocalBillingAddress(address) {
    Vue.$cookies.set('local_billing_address', JSON.stringify(address),
      this.onMonthFromNow())
  }

  loadLocalAddresses() {
    let shippingAddress = this.loadLocalShippingAddress()
    let billingAddress = this.loadLocalBillingAddress()
    if (shippingAddress != null)
      shippingAddress['type'] = 'shipping'
    if (billingAddress != null)
      billingAddress['type'] = 'billing'
    return [shippingAddress, billingAddress]
  }

}