import Vue from 'vue'
import Log from '@/utils/logger'

Vue.mixin({
  methods: {
    logout() {
      this.$confirm({
          message: this.$t('logout_question'),
          button: {
            yes: this.$t('yes'),
            no: this.$t('no'),
          },
          callback: confirm => {
            if (confirm) {
              localStorage.removeItem('cookie:accepted');
              this.$store.commit('logout')
              window.location.href = '/'
            }
          },
        },
      )
    },
    authFold(call, unauthenticatedCall) {
      if (this.isAuthenticated()) {
        if (call instanceof Function) {
          return call()
        }
      } else if (unauthenticatedCall != null) {
        if (unauthenticatedCall instanceof Function) {
          return unauthenticatedCall()
        }
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
    isAuthenticated() {
      if (this.$store.getters.isAuthenticated) {
        return this.$store.getters.isAuthenticated
      } else {
        return false
      }
    },
  },
  computed: {
    user() {
      if (this.$store.getters.user != null) {
        return this.$store.getters.user
      } else {
        return null
      }
    },
  },
})